import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { NodeToolbar } from "@xyflow/react";
import useDeleteEdgesAndNodes from "../hooks/useDeleteEdgesAndNodes";
import useSaveNode from "../hooks/useSaveNode";
import ColorPicker from "./ColorPicker";

export default function NodeEditBar({
  color,
  id,
  isEditing,
  labelCurrent,
  labelPersisted,
  selected,
  setLabel,
}: {
  color: string | undefined;
  id: string;
  isEditing: boolean;
  labelCurrent: string;
  labelPersisted: string;
  selected: boolean | undefined;
  setLabel: (label: string) => void;
}) {
  const deleteEdgesAndNodes = useDeleteEdgesAndNodes();
  const saveNode = useSaveNode();

  const className =
    "rounded-md inline-flex bg-white p-1 text-black hover:opacity-90 disabled:text-gray-300";

  const disabled = labelCurrent === labelPersisted;

  const handleCancelClick = () => {
    setLabel(labelPersisted);
  };

  const handleDeleteClick = () => {
    deleteEdgesAndNodes();
  };

  const handleSaveClick = () => {
    saveNode({ id, valueNew: labelCurrent, valueOld: labelPersisted });
  };

  return (
    <NodeToolbar
      className="flex flex-row items-end space-x-2"
      offset={15}
      isVisible={isEditing && selected}
    >
      <button
        className={className}
        disabled={disabled}
        onClick={handleCancelClick}
      >
        <XMarkIcon className="h-6 w-6" />
      </button>

      {id !== "1" && (
        <ColorPicker className={className} color={color} id={id} />
      )}

      <button className={className} onClick={handleDeleteClick}>
        <TrashIcon className="h-6 w-6" />
      </button>

      <button
        className={className}
        disabled={disabled}
        onClick={handleSaveClick}
      >
        <CheckIcon className="h-6 w-6" />
      </button>
    </NodeToolbar>
  );
}
