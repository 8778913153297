import { useReactFlow } from "@xyflow/react";
import { useEffect } from "react";
import useGlobalContext from "./useGlobalContext";
import useUnhideAllNodes from "./useUnhideAllNodes";

declare global {
  interface Window {
    collapse: () => void;
    expand: () => void;
  }
}

export default function useCollapseAndExpand() {
  const unhideAllNodes = useUnhideAllNodes();
  const { fitView, setNodes } = useReactFlow();
  const { setIsExpanded } = useGlobalContext();

  const collapse = () => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const hidden = node.id.split(".").length > 2;

        return { ...node, hidden: hidden };
      }),
    );

    setIsExpanded(false);

    setTimeout(() => {
      fitView({ duration: 1000 });
    }, 0);
  };

  const expand = () => {
    unhideAllNodes();

    setTimeout(() => {
      fitView({ duration: 1000 });
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "collapse") {
        collapse();
      }

      if (event.data === "expand") {
        expand();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.collapse = () => {
      collapse();
    };

    window.expand = () => {
      expand();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { collapse, expand };
}
