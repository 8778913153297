import { Handle, Position } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import { getBorderColor } from "../helpers/colors";
import useGlobalContext from "../hooks/useGlobalContext";
import { NodeProps } from "../types";
import AutoGrowingTextarea from "./AutoGrowingTextarea";
import NodeEditBar from "./NodeEditBar";

export default function Level3Node({ data, id, selected }: NodeProps) {
  const [label, setLabel] = useState<string>(data.label);

  const { isEditing } = useGlobalContext();

  const borderColor = getBorderColor(id);

  const outline =
    isEditing && selected
      ? "outline-dashed outline-4 outline-offset-8 outline-white"
      : "";

  const overridingStyle = data?.color ? { borderColor: data.color } : {};

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      setLabel(value);
    },
    [],
  );

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  return (
    <div
      className={`${borderColor} ${outline} max-w-96 rounded-full border-2 bg-custom-black-light px-6 py-4 text-white`}
      style={overridingStyle}
    >
      <NodeEditBar
        color={data.color}
        id={id}
        isEditing={isEditing}
        labelCurrent={label}
        labelPersisted={data.label}
        selected={selected}
        setLabel={setLabel}
      />

      <Handle type="target" position={Position.Top} />

      <AutoGrowingTextarea
        className="text-xl font-semibold"
        name="label"
        onChange={handleChange}
        readOnly={!isEditing}
        value={label}
      />

      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}
