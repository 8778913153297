import * as Sentry from "@sentry/react";
import { Node, ReactFlowState, useReactFlow, useStore } from "@xyflow/react";
import {
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
  forceX,
  forceY,
  SimulationLinkDatum,
  SimulationNodeDatum,
} from "d3-force";
import { useEffect, useState } from "react";
import usePersistEditedEdgesAndNodes from "./usePersistEditedEdgesAndNodes";

type SimNodeType = SimulationNodeDatum & Node;

const elementCountSelector = (state: ReactFlowState) =>
  state.nodeLookup.size + state.edges.length;

export default function useForceLayout() {
  const [isLayouting, setIsLayouting] = useState(false);

  const elementCount = useStore(elementCountSelector);
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { fitView, getEdges, getNodes, setNodes } = useReactFlow();

  useEffect(() => {
    const edges = getEdges();
    const nodes = getNodes();

    if (!nodes.length) {
      return;
    }

    const areNodesLayouted = nodes.every(
      (node) => node.position.x !== 0 && node.position.y !== 0,
    );

    if (areNodesLayouted) {
      fitView({ duration: 1000 });
      return;
    }

    setIsLayouting(true);

    const simulationNodes: SimNodeType[] = nodes.map((node) => ({
      ...node,
      x: node.position.x,
      y: node.position.y,
    }));

    const simulationNodeIds = simulationNodes.map((node) => node.id);

    const simulationLinks: SimulationLinkDatum<SimNodeType>[] = edges
      .map((edge) => edge)
      // NOTE: Filter out edges with non existing nodes as the LLM halluzinates sometimes.
      .filter((edge) => {
        const sourceNodeExists = simulationNodeIds.includes(edge.source);
        const targetNodeExists = simulationNodeIds.includes(edge.target);

        if (!sourceNodeExists) {
          Sentry.captureException(`Source node does not exist: ${edge.source}`);
        }

        if (!targetNodeExists) {
          Sentry.captureException(`Target node does not exist: ${edge.target}`);
        }

        return sourceNodeExists && targetNodeExists;
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const x = (d: any) => {
      const level = d.id.split(".").length;
      const unit = 300;
      const factor = 0.75;

      switch (d.id.slice(0, 3)) {
        case "1.1":
        case "1.6":
          return -unit * level * factor;
        case "1.2":
        case "1.5":
          return 0;
        case "1.3":
        case "1.4":
          return unit * level * factor;
        default:
          return 0;
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const y = (d: any) => {
      const level = d.id.split(".").length;
      const unit = 300;
      const factor = 0.75;

      switch (d.id.slice(0, 3)) {
        case "1.1":
        case "1.2":
        case "1.3":
          return -unit * level * factor;
        case "1.4":
        case "1.5":
        case "1.6":
          return unit * level * factor;
        default:
          return 0;
      }
    };

    const calculateCenterX = () => {
      const rootNodeWidth = nodes.find((node) => node.id === "1")?.measured
        ?.width;

      if (!rootNodeWidth) return 0;

      return 0 - rootNodeWidth / 2;
    };

    const calculateCenterY = () => {
      const rootNodeHeight = nodes.find((node) => node.id === "1")?.height;

      if (!rootNodeHeight) return 0;

      return 0 - rootNodeHeight / 2;
    };

    const simulation = forceSimulation()
      .nodes(simulationNodes)
      .force("charge", forceManyBody().strength(-5000))
      .force("center", forceCenter(calculateCenterX(), calculateCenterY()))
      .force(
        "link",
        forceLink(simulationLinks)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .id((d: any) => d.id)
          .strength(1)
          .distance(200),
      )
      .force("x", forceX().x(x).strength(0.1))
      .force("y", forceY().y(y).strength(0.1))
      .on("tick", () => {
        setNodes((nodes) =>
          nodes.map((node, index) => {
            const { x, y } = simulationNodes[index];

            return { ...node, position: { x: x ?? 0, y: y ?? 0 } };
          }),
        );

        fitView();
      })
      .on("end", async () => {
        persistEditedEdgesAndNodes();

        setIsLayouting(false);
        fitView({ duration: 1000 });
      });

    return () => {
      simulation.stop();
    };
    // TODO: With the `persistEditedEdgesAndNodes` dependency the layouting never stops.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elementCount,
    fitView,
    getEdges,
    getNodes,
    // persistEditedEdgesAndNodes,
    setNodes,
  ]);

  return { isLayouting };
}
