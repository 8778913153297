import { Handle, Position } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import { getTextColor } from "../helpers/colors";
import useGlobalContext from "../hooks/useGlobalContext";
import { NodeProps } from "../types";
import AutoGrowingTextarea from "./AutoGrowingTextarea";
import NodeEditBar from "./NodeEditBar";

export default function Level4Node({ data, id, selected }: NodeProps) {
  const [label, setLabel] = useState<string>(data.label);

  const { isEditing } = useGlobalContext();

  const outline =
    isEditing && selected
      ? "outline-dashed outline-4 outline-offset-8 outline-white"
      : "";

  const overridingStyle = data.color ? { color: data.color } : {};

  const textColor = getTextColor(id);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      setLabel(value);
    },
    [],
  );

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  return (
    <div
      className={`${outline} ${textColor} max-w-96 rounded-lg bg-custom-background p-2`}
      style={overridingStyle}
    >
      <NodeEditBar
        color={data.color}
        id={id}
        isEditing={isEditing}
        labelCurrent={label}
        labelPersisted={data.label}
        selected={selected}
        setLabel={setLabel}
      />

      <Handle type="target" position={Position.Top} />

      <AutoGrowingTextarea
        className="text-xl font-semibold"
        name="label"
        onChange={handleChange}
        readOnly={!isEditing}
        value={label}
      />

      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}
