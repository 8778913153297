import { ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import MindMap from "../components/MindMap";
import useGlobalContext from "../hooks/useGlobalContext";

export default function MindMapPage() {
  const [searchParams] = useSearchParams();

  const { setUserAccessToken, setUserIsPro, setUserRefreshToken } =
    useGlobalContext();

  useEffect(() => {
    setUserAccessToken(searchParams.get("access"));
    setUserRefreshToken(searchParams.get("refresh"));
    setUserIsPro(searchParams.get("pro") === "true");
  }, [searchParams, setUserAccessToken, setUserIsPro, setUserRefreshToken]);

  return (
    <div className="flex h-dvh w-screen flex-col">
      <ReactFlowProvider>
        <MindMap />
      </ReactFlowProvider>
    </div>
  );
}
