export const getBgColor = (id: string) => {
  switch (matchId(id)) {
    case 0:
      return "bg-custom-red";
    case 1:
      return "bg-custom-blue";
    case 2:
      return "bg-custom-yellow";
    case 3:
      return "bg-custom-purple";
    case 4:
      return "bg-custom-yellow-light";
    case 5:
      return "bg-custom-green";
    default:
      return "bg-white";
  }
};

export const getBorderColor = (id: string) => {
  switch (matchId(id)) {
    case 0:
      return "border-custom-red";
    case 1:
      return "border-custom-blue";
    case 2:
      return "border-custom-yellow";
    case 3:
      return "border-custom-purple";
    case 4:
      return "border-custom-yellow-light";
    case 5:
      return "border-custom-green";
    default:
      return "border-white";
  }
};

// TODO: Reuse the colors from the Tailwind config theme,
// instead of redefining them here.
export const getColor = (id: string) => {
  switch (matchId(id)) {
    case 0:
      return "#FFB3B3";
    case 1:
      return "#B0ECFF";
    case 2:
      return "#FFE890";
    case 3:
      return "#D1AAFF";
    case 4:
      return "#FFF9D8";
    case 5:
      return "#A2E2A9";
    default:
      return "#FFFFFF";
  }
};

export const getTextColor = (id: string) => {
  switch (matchId(id)) {
    case 0:
      return "text-custom-red";
    case 1:
      return "text-custom-blue";
    case 2:
      return "text-custom-yellow";
    case 3:
      return "text-custom-purple";
    case 4:
      return "text-custom-yellow-light";
    case 5:
      return "text-custom-green";
    default:
      return "text-white";
  }
};

const matchId = (id: string) => {
  return Number(id.split(".")[1]) % 6;
};
