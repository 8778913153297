import { ReactFlow, useEdgesState, useNodesState } from "@xyflow/react";
import { useSearchParams } from "react-router-dom";
import { edgeTypes, nodeTypes } from "../helpers/edgeAndNodeTypes";
import useCollapseAndExpand from "../hooks/useCollapseAndExpand";
import useExport from "../hooks/useExport";
import useFetchAndSubscribeToMindMap from "../hooks/useFetchAndSubscribeToMindMap";
import useForceLayout from "../hooks/useForceLayout";
import useGlobalContext from "../hooks/useGlobalContext";
import useHandleNodeClick from "../hooks/useHandleNodeClick";
import useHandleSelectedNode from "../hooks/useHandleSelectedNode";
import useNavigateToNodes from "../hooks/useNavigateToNodes";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";
import ControlsPanel from "./ControlsPanel";

export default function MindMap() {
  const [edges, , onEdgesChange] = useEdgesState([]);
  const [nodes, , onNodesChange] = useNodesState([]);
  const [searchParams] = useSearchParams();
  const { isEditing } = useGlobalContext();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();

  const showControls = searchParams.get("controls") === "true";

  const defaultViewport = {
    x: typeof window !== "undefined" ? window.innerWidth / 2 : 0,
    y: typeof window !== "undefined" ? window.innerHeight / 2 : 0,
    zoom: 0.6,
  };

  useHandleSelectedNode();

  useExport();

  useFetchAndSubscribeToMindMap();

  const { isLayouting } = useForceLayout();

  const { handleNodeClick } = useHandleNodeClick();

  const { collapse, expand } = useCollapseAndExpand();

  const { navigateToNextNode, navigateToPreviousNode } = useNavigateToNodes();

  return (
    <ReactFlow
      className="bg-custom-background"
      defaultViewport={defaultViewport}
      edgeTypes={edgeTypes}
      edges={edges}
      maxZoom={1.6}
      minZoom={0.2}
      nodeTypes={nodeTypes}
      nodes={nodes}
      nodesConnectable={false}
      nodesDraggable={!isEditing}
      onNodeDragStop={persistEditedEdgesAndNodes}
      onEdgesChange={onEdgesChange}
      onNodeClick={handleNodeClick}
      onNodesChange={onNodesChange}
      panOnScroll
      proOptions={{ account: "paid-pro", hideAttribution: true }}
      selectNodesOnDrag={false}
      zoomOnDoubleClick={false}
    >
      {showControls && (
        <ControlsPanel
          collapse={collapse}
          expand={expand}
          isLayouting={isLayouting}
          navigateToNextNode={navigateToNextNode}
          navigateToPreviousNode={navigateToPreviousNode}
        />
      )}
    </ReactFlow>
  );
}
