import { Edge, Node } from "@xyflow/react";
import { createContext, FC, ReactNode, useState } from "react";
import { Version } from "../types";

export type GlobalContextProps = {
  isEditing: boolean;
  isExpanded: boolean;
  isInteracted: boolean;
  selectedNodeId: string | undefined;
  setIsEditing: (isEditing: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  setIsInteracted: (isInteracted: boolean) => void;
  setSelectedNodeId: (selectedNodeId: string | undefined) => void;
  setUserAccessToken: (userAccessToken: string | null) => void;
  setUserIsPro: (userIsPro: boolean) => void;
  setUserRefreshToken: (userRefreshToken: string | null) => void;
  setVersionIndex: (versionIndex: number) => void;
  setVersions: (versions: Version[]) => void;
  userAccessToken: string | null;
  userIsPro: boolean;
  userRefreshToken: string | null;
  versionIndex: number;
  versions: { edges: Edge[]; nodes: Node[] }[];
};

export const GlobalContext = createContext<GlobalContextProps | undefined>(
  undefined,
);

export const GlobalContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isInteracted, setIsInteracted] = useState<boolean>(false);
  const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>();
  const [userAccessToken, setUserAccessToken] = useState<string | null>(null);
  const [userIsPro, setUserIsPro] = useState<boolean>(false);
  const [userRefreshToken, setUserRefreshToken] = useState<string | null>(null);
  const [versionIndex, setVersionIndex] = useState<number>(0);
  const [versions, setVersions] = useState<Version[]>([]);

  return (
    <GlobalContext.Provider
      value={{
        isEditing,
        isExpanded,
        isInteracted,
        selectedNodeId,
        setIsEditing,
        setIsExpanded,
        setIsInteracted,
        setSelectedNodeId,
        setUserAccessToken,
        setUserIsPro,
        setUserRefreshToken,
        setVersionIndex,
        setVersions,
        userAccessToken,
        userIsPro,
        userRefreshToken,
        versionIndex,
        versions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
