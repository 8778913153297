import { Node, useReactFlow } from "@xyflow/react";
import useCenterNode from "./useCenterNode";
import useGlobalContext from "./useGlobalContext";

export default function useHandleNodeClick() {
  const centerNode = useCenterNode();
  const { fitView, setNodes } = useReactFlow();
  const { isEditing, isInteracted, setIsInteracted } = useGlobalContext();

  const unhideBranchNodes = (clickedNodeId: string) => {
    setNodes((nodes: Node[]) =>
      nodes.map((node) => {
        if (
          node.id.split(".").length > 2 &&
          node.id.startsWith(clickedNodeId)
        ) {
          return { ...node, hidden: false };
        } else {
          return node;
        }
      }),
    );
  };

  const handleNodeClick = (_event: React.MouseEvent, node: Node) => {
    const clickedNodeId = node.id;

    if (!isInteracted) setIsInteracted(true);

    if (clickedNodeId.split(".").length < 3) {
      unhideBranchNodes(clickedNodeId);
    }

    isEditing || clickedNodeId !== "1"
      ? centerNode(node)
      : fitView({ duration: 1000 });
  };

  return {
    handleNodeClick,
  };
}
