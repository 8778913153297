import { Edge, Node, useReactFlow } from "@xyflow/react";
import { useState } from "react";
import { getBgColor } from "../helpers/colors";
import useAddVersion from "../hooks/useAddVersion";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";

export default function ColorPicker({
  className,
  color,
  id,
}: {
  className: string;
  color: string | undefined;
  id: string;
}) {
  const [displayColors, setDisplayColors] = useState(false);

  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();

  const bgColor = getBgColor(id);
  const overridingStyle = color ? { backgroundColor: color } : {};

  const handleColorClick = (color: string) => {
    setDisplayColors(!displayColors);

    const edges = getEdges();
    const nodes = getNodes();

    const newNodes: Node[] = nodes.map((node) => {
      if (node.id.startsWith(id)) {
        return { ...node, data: { ...node.data, color } };
      }

      return node;
    });

    const newEdges: Edge[] = edges.map((edge) => {
      if (edge.source.startsWith(id) || edge.target.startsWith(id)) {
        return { ...edge, data: { ...edge.data, color } };
      }

      return edge;
    });

    setEdges(newEdges);
    setNodes(newNodes);

    addVersion({ edges: newEdges, nodes: newNodes });

    persistEditedEdgesAndNodes();
  };

  return (
    <div className="flex flex-col space-y-2">
      {(color ? color !== "#B0ECFF" : bgColor !== "bg-custom-blue") && (
        <button
          className={`${className} ${displayColors ? "visible opacity-100" : "invisible opacity-0"} transition-opacity duration-1000`}
          onClick={() => handleColorClick("#B0ECFF")}
        >
          <span className="m-1 h-4 w-4 rounded-full bg-custom-blue" />
        </button>
      )}

      {(color ? color !== "#FFE890" : bgColor !== "bg-custom-yellow") && (
        <button
          className={`${className} ${displayColors ? "visible opacity-100" : "invisible opacity-0"} transition-opacity duration-700`}
          onClick={() => handleColorClick("#FFE890")}
        >
          <span className="m-1 h-4 w-4 rounded-full bg-custom-yellow" />
        </button>
      )}

      {(color ? color !== "#D1AAFF" : bgColor !== "bg-custom-purple") && (
        <button
          className={`${className} ${displayColors ? "visible opacity-100" : "invisible opacity-0"} transition-opacity duration-500`}
          onClick={() => handleColorClick("#D1AAFF")}
        >
          <span className="m-1 h-4 w-4 rounded-full bg-custom-purple" />
        </button>
      )}

      {(color ? color !== "#FFF9D8" : bgColor !== "bg-custom-yellow-light") && (
        <button
          className={`${className} ${displayColors ? "visible opacity-100" : "invisible opacity-0"} transition-opacity duration-300`}
          onClick={() => handleColorClick("#FFF9D8")}
        >
          <span className="m-1 h-4 w-4 rounded-full bg-custom-yellow-light" />
        </button>
      )}

      {(color ? color !== "#A2E2A9" : bgColor !== "bg-custom-green") && (
        <button
          className={`${className} ${displayColors ? "visible opacity-100" : "invisible opacity-0"} transition-opacity duration-200`}
          onClick={() => handleColorClick("#A2E2A9")}
        >
          <span className="m-1 h-4 w-4 rounded-full bg-custom-green" />
        </button>
      )}

      {(color ? color !== "#FFB3B3" : bgColor !== "bg-custom-red") && (
        <button
          className={`${className} ${displayColors ? "visible opacity-100" : "invisible opacity-0"} transition-opacity duration-100`}
          onClick={() => handleColorClick("#FFB3B3")}
        >
          <span className="m-1 h-4 w-4 rounded-full bg-custom-red" />
        </button>
      )}

      <button
        className={className}
        onClick={() => setDisplayColors(!displayColors)}
      >
        <span
          className={`${bgColor} m-1 h-4 w-4 rounded-full `}
          style={overridingStyle}
        />
      </button>
    </div>
  );
}
