import {
  getNodesBounds,
  getViewportForBounds,
  useReactFlow,
} from "@xyflow/react";
import { toJpeg } from "html-to-image";
import { useEffect } from "react";
import useUnhideAllNodes from "./useUnhideAllNodes";

declare global {
  interface Window {
    exportImageData: () => Promise<string>;
  }
}

export default function useExport() {
  const unhideAllNodes = useUnhideAllNodes();
  const { getNodes } = useReactFlow();

  async function exportImageData() {
    unhideAllNodes();

    await new Promise((resolve) => setTimeout(resolve, 3000));

    const imageLength = 1024;
    const nodesBounds = getNodesBounds(getNodes());
    const viewport = getViewportForBounds(
      nodesBounds,
      imageLength,
      imageLength,
      0.1,
      2,
      0.1,
    );

    return await toJpeg(
      document.querySelector(".react-flow__viewport") as HTMLElement,
      {
        backgroundColor: "#171B23",
        height: imageLength,
        quality: 1,
        style: {
          height: `${imageLength}px`,
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
          width: `${imageLength}px`,
        },
        width: imageLength,
      },
    );
  }

  useEffect(() => {
    window.exportImageData = () => exportImageData();

    window.addEventListener("message", (event) => {
      if (event.data === "exportImageData") {
        exportImageData();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
