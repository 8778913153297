import { Edge, Node, useReactFlow } from "@xyflow/react";
import useAddVersion from "./useAddVersion";
import useGlobalContext from "./useGlobalContext";
import usePersistEditedEdgesAndNodes from "./usePersistEditedEdgesAndNodes";

export default function useDeleteEdgesAndNodes() {
  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const { setSelectedNodeId } = useGlobalContext();

  const deleteEdgesAndNodes = () => {
    const toBeDeletedNodeIds = getToBeDeletedNodeIds();

    if (!toBeDeletedNodeIds.length) return;

    const newEdges = getEdges().filter(
      (edge: Edge) =>
        !toBeDeletedNodeIds.includes(edge.source) ||
        !toBeDeletedNodeIds.includes(edge.target),
    );

    const newNodes = getNodes().filter(
      (node: Node) => !toBeDeletedNodeIds.includes(node.id),
    );

    setEdges(newEdges);
    setNodes(newNodes);

    addVersion({ edges: newEdges, nodes: newNodes });

    setSelectedNodeId(undefined);

    persistEditedEdgesAndNodes();
  };

  const getToBeDeletedNodeIds = () => {
    const nodes = getNodes();

    const selectedNodeIds = nodes
      .filter((node: Node) => node.selected)
      .map((node: Node) => node.id);

    return nodes
      .filter((node: Node) =>
        selectedNodeIds.some((nodeId) => node.id.startsWith(nodeId)),
      )
      .map((node: Node) => node.id);
  };

  return deleteEdgesAndNodes;
}
