import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Edge, Node, Panel, useReactFlow } from "@xyflow/react";
import { isAndroid, isIOS } from "react-device-detect";
import useGlobalContext from "../hooks/useGlobalContext";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";
import usePostIsEditingToReactNativeWebView from "../hooks/usePostIsEditingToReactNativeWebView";

type Props = {
  collapse: () => void;
  expand: () => void;
  isLayouting: boolean;
  navigateToNextNode: () => void;
  navigateToPreviousNode: () => void;
};

export default function ControlsPanel({
  collapse,
  expand,
  isLayouting,
  navigateToNextNode,
  navigateToPreviousNode,
}: Props) {
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const postIsEditingToReactNativeWebView =
    usePostIsEditingToReactNativeWebView();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();

  const {
    isEditing,
    isExpanded,
    isInteracted,
    setIsEditing,
    setIsInteracted,
    setVersionIndex,
    setVersions,
    userAccessToken,
    userRefreshToken,
    userIsPro,
    versionIndex,
    versions,
  } = useGlobalContext();

  const handleCancelClick = () => {
    setIsEditing(false);

    postIsEditingToReactNativeWebView(false);
  };

  const handleEditClick = () => {
    postIsEditingToReactNativeWebView(true);

    if (!userIsPro) return;

    if (!isInteracted) setIsInteracted(true);

    const edges: Edge[] = getEdges();
    const nodes: Node[] = getNodes();

    const newVersions = versions.length
      ? versions
      : [...versions, { edges, nodes }];

    setVersions(newVersions);

    setIsEditing(true);
  };

  const handleExpandClick = () => {
    isExpanded ? collapse() : expand();
  };

  const handleNextClick = () => {
    navigateToNextNode();
  };

  const handlePreviousClick = () => {
    navigateToPreviousNode();
  };

  const handleRedoClick = () => {
    const nextVersionIndex = versionIndex + 1;
    const nextVersion = versions[nextVersionIndex];
    setVersionIndex(nextVersionIndex);
    setEdges(nextVersion.edges);
    setNodes(nextVersion.nodes);
    persistEditedEdgesAndNodes();
  };

  const handleUndoClick = () => {
    const previousVersionIndex = versionIndex - 1;
    const previousVersion = versions[previousVersionIndex];
    setVersionIndex(previousVersionIndex);
    setEdges(previousVersion.edges);
    setNodes(previousVersion.nodes);
    persistEditedEdgesAndNodes();
  };

  const marginBottom = () => {
    if (isAndroid || isIOS) {
      return "mb-40";
    }

    return "mb-4";
  };

  const panelButtonClassName = isEditing
    ? "panel-button--edit"
    : "panel-button";

  return (
    <Panel
      className={`m-0 ${marginBottom()} overflow-hidden whitespace-nowrap rounded-xl`}
      position="bottom-center"
      style={{ zIndex: 2000 }}
    >
      <button
        className={panelButtonClassName}
        disabled={isLayouting}
        onClick={handleExpandClick}
      >
        {isExpanded ? (
          <ArrowsPointingInIcon className="panel-button-icon" />
        ) : (
          <ArrowsPointingOutIcon className="panel-button-icon" />
        )}
      </button>

      {userAccessToken &&
        userRefreshToken &&
        (isEditing ? (
          <button
            className={`${panelButtonClassName} border-l-[0.5px] border-custom-grey`}
            disabled={isLayouting}
            onClick={handleCancelClick}
          >
            <XMarkIcon className="panel-button-icon" />
          </button>
        ) : (
          <button
            className={`${panelButtonClassName} border-l-[0.5px] border-custom-grey`}
            disabled={isLayouting}
            onClick={handleEditClick}
          >
            <PencilIcon className="panel-button-icon" />
          </button>
        ))}

      {isEditing ? (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-grey`}
          disabled={versions.length < 2 || versionIndex === 0}
          onClick={handleUndoClick}
        >
          <ArrowUturnLeftIcon className="panel-button-icon" />
        </button>
      ) : (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-grey`}
          disabled={isLayouting}
          onClick={handlePreviousClick}
        >
          <ArrowLeftIcon className="panel-button-icon" />
        </button>
      )}

      {isEditing ? (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-grey`}
          disabled={versions.length < 2 || versionIndex === versions.length - 1}
          onClick={handleRedoClick}
        >
          <ArrowUturnRightIcon className="panel-button-icon" />
        </button>
      ) : (
        <button
          className={`${panelButtonClassName} border-l-[0.5px] border-custom-grey`}
          disabled={isLayouting}
          onClick={handleNextClick}
        >
          <ArrowRightIcon className="panel-button-icon" />
        </button>
      )}
    </Panel>
  );
}
