import { Edge, Node } from "@xyflow/react";
import useGlobalContext from "./useGlobalContext";

export default function useAddVersion() {
  const { setVersionIndex, setVersions, versionIndex, versions } =
    useGlobalContext();

  const addVersion = ({ edges, nodes }: { edges: Edge[]; nodes: Node[] }) => {
    const newVersionIndex = versionIndex + 1;

    setVersionIndex(newVersionIndex);

    const newVersions = [
      ...versions.slice(0, versionIndex + 1),
      { edges, nodes },
    ];

    setVersions(newVersions);
  };

  return addVersion;
}
